var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"height":"400px"},attrs:{"center":{ lat: 0, lng: 0 },"options":{
        mapTypeControl: false,
        gestureHandling: 'greedy',
        styles: _vm.$vuetify.theme.dark ? _vm.$vuetify.theme.themes.dark.gmap : _vm.$vuetify.theme.themes.light.gmap,
    },"zoom":0,"map-type-id":"roadmap"}},[(!_vm.item.multiplayer)?_c('div',_vm._l((_vm.item.rounds),function(r,index){return _c('div',{key:index},[_c('GmapMarker',{attrs:{"position":{ lat: r.guess.lat, lng: r.guess.lng }}}),_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":r.guess}},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('Maps.infoWindow.Distance'))+": ")]),_vm._v(" "+_vm._s(new Intl.NumberFormat(_vm.$i18n.locale, { style: "unit", unit:"kilometer" }).format(r.distance / 1000))+" "),_c('br'),_c('b',[_vm._v(" "+_vm._s(_vm.$t('Maps.infoWindow.Points'))+": ")]),_vm._v(" "+_vm._s(r.points)+" ")])]),_c('GmapPolyline',{attrs:{"path":[
                    { lat: r.position.lat, lng: r.position.lng },
                    {
                        lat: r.guess.lat,
                        lng: r.guess.lng,
                    } ],"options":{
                    strokeOpacity: 0,

                    icons: [
                        {
                            icon: {
                                path: 'M 0,-1 0,1',
                                strokeOpacity: 1,
                                scale: 2,
                            },
                            offset: '0',
                            repeat: '10px',
                        } ],
                }}}),_c('GmapMarker',{attrs:{"icon":_vm.icon,"position":{ lat: r.position.lat, lng: r.position.lng }}})],1)}),0):_c('div',_vm._l((_vm.item.rounds),function(r,indexR){return _c('div',{key:indexR},[_vm._l((Object.keys(r.players)),function(player,indexP){return _c('div',{key:indexR + '' + indexP},[_c('GmapMarker',{attrs:{"label":player && player.length > 0
                            ? player[0].toUpperCase()
                            : undefined,"position":{
                        lat: r.players[player].guess.lat,
                        lng: r.players[player].guess.lng,
                    }}}),_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":{
                        lat: r.players[player].guess.lat,
                        lng: r.players[player].guess.lng,
                    }}},[_c('p',[_c('b',[_vm._v(_vm._s(player))]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$t('Maps.infoWindow.Distance'))+" : ")]),_vm._v(" "+_vm._s(new Intl.NumberFormat(_vm.$i18n.locale, { style: "unit", unit:"kilometer" }).format(r.players[player].distance / 1000))+" "),_c('br'),_c('b',[_vm._v(" "+_vm._s(_vm.$t('Maps.infoWindow.Points'))+": ")]),_vm._v(" "+_vm._s(r.players[player].points)+" ")])]),_c('GmapPolyline',{attrs:{"path":[
                        { lat: r.position.lat, lng: r.position.lng },
                        {
                            lat: r.players[player].guess.lat,
                            lng: r.players[player].guess.lng,
                        } ],"options":{
                        strokeColor:
                            _vm.strokeColors[indexP % _vm.strokeColors.length],
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: {
                                    path: 'M 0,-1 0,1',
                                    strokeOpacity: 1,
                                    scale: 2,
                                },
                                offset: '0',
                                repeat: '10px',
                            } ],
                    }}})],1)}),_c('GmapMarker',{attrs:{"icon":_vm.icon,"position":{ lat: r.position.lat, lng: r.position.lng }}})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }